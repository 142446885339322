defineDs('DanskeSpil/Domain/Dantoto/Scripts/CookiePolyfill', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
], function (Utils) {

  if (window.$DS) {
    // MIPE: Still in use per 25/1-2022, from Sportech/Dantoto, approx. 650 per day (...)
    // logDeprecated('jQuery.cookie', key, value, options);
    $DS.cookie = Utils.cookie;
  }

});
