defineDs('DanskeSpil/Domain/Dantoto/Scripts/SportechWidget', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
], function (Utils) {

  /* Map between widget type and the API */
  var widgetTypes = {
    G4_ACTIVE_WIDGET: 'G4JSAPI',
    G4_CALENDAR_WIDGET: 'G4JSAPI',
    G4_KOUPON_WIDGET: 'G4JSAPI',
    G4_My_QUEUE_WIDGET: 'G4JSAPI',  // eslint-disable-line camelcase -- Not our model
    G4_PAST_WIDGET: 'G4JSAPI',
    G4_TRACK_WIDGET: 'G4JSAPI',
    G4_VIDEOS_WIDGET: 'G4JSAPI',
    G4_WAGER_WIDGET: 'G4JSAPI',
    G4MWP_WAGERPAD: 'G4MWPJSAPI',
    G4FH_WIDGET: 'G4FHJSAPI',
    G4SQP_WIDGET: 'G4SQPJSAPI',
    G4HT_WIDGET: 'G4HTJSAPI',
    G4EXP_WIDGET: 'G4EXPERTTIPSAPI'
  };

  /* The Sportech JS API endpoint URL's */
  var apiEndpoints = {
    G4JSAPI: DS.Config.SPORTECH_DANTOTO_URL + '/dantoto/js/g4widgetframework.js', // Main Framework
    G4MWPJSAPI: DS.Config.SPORTECH_DANTOTO_URL + '/walletbridge/miniwagerpad/api/g4mwpframework.js', // Mini Wager Pad
    G4FHJSAPI: DS.Config.SPORTECH_DANTOTO_URL + '/walletbridge/favhorse/api/g4fhframework.js', // Favorite Horse
    G4SQPJSAPI: DS.Config.SPORTECH_DANTOTO_URL + '/walletbridge/sqp/api/g4sqpframework.js', // Smart Quick Pick
    G4HTJSAPI: DS.Config.SPORTECH_DANTOTO_URL + '/walletbridge/hottips/api/g4hottipsframework.js', // Hot Tips
    G4EXPERTTIPSAPI: DS.Config.SPORTECH_DANTOTO_URL + '/walletbridge/experttips/api/g4experttipsframework.js' // Expert Tips
  };

  /* The lists of found widgets, grouped by their API */
  var widgets = {
    G4JSAPI: [],
    G4MWPJSAPI: [],
    G4FHJSAPI: [],
    G4SQPJSAPI: [],
    G4HTJSAPI: [],
    G4EXPERTTIPSAPI: []
  };

  var iterate = function () {
    var incrementCount = 0;

    /* Special case for the results widget - this is an iframe */
    var SPORTECH_RESULTS = DS.Config.SPORTECH_DANTOTO_URL + '/walletbridge/results/';
    document.querySelectorAll('.js-dantoto-results-widget iframe').forEach(function (element) {
      element.src = SPORTECH_RESULTS;
    });

    document.querySelectorAll('.js-dantoto-widget').forEach(function ($widget) {
      var id = $widget.id;

      /* Generate id to the DOM element, if not already set */
      if (!id) {
        incrementCount += 1;
        id = 'js-generated-dantoto-widget-' + incrementCount;
        $widget.id = id;
      }

      var widgetType = $widget.getAttribute('data-widget-name'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      var api = widgetTypes[widgetType] || '';

      if (widgetType && api) {

        var params = {
          widgetType: widgetType,
          containerId: id
        };

        /* Some of the widgets supports extra params, passed from the query string vars */
        var queryParams = ['betType', 'meet', 'perf', 'race'];
        queryParams.forEach(function (v) {
          if (Utils.getParameter(v)) {
            params[v] = Utils.getParameter(v);
          }
        });
        widgets[api].push(params);
      }
    });

    /* Walk through each widget, grouped by their API */
    Object.keys(widgets).forEach(function (api) {
      if (widgets[api].length > 0) {

        include(apiEndpoints[api], function () {

          /* The JS API is now loaded, lets load the widgets */
          widgets[api].forEach(function (widgetParams) {

            if (window[api]) {
              console.debug('Load widget ' + widgetParams.containerId);
              if (typeof window[api] !== 'function') {
                window[api].loadWidget(widgetParams);
              } else {
                window[api]().loadWidget(widgetParams);
              }
            } else {
              console.error(api + ' not found');
            }
          });
        });
      }
    });
  };

  var init = function () {
    DSAPI.ready(function () {
      iterate();
    });
  };

  document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('.js-dantoto-results-widget, .js-dantoto-widget') && document.body.classList.contains('mode-normal')) {
      init();
    }
  });
});
