defineDs('DanskeSpil/Domain/Dantoto/Scripts/Print', function () {

  // logDeprecated: Still in use per 6/2-2020
  document.querySelectorAll('.js-print-page').forEach(function (element) {
    element.addEventListener('click', function () {
      window.print();
    });
  });

});
